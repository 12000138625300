import React, { useRef } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"

import { Cover } from "components/fostive/cover/cover"
import { Card } from "components/anti/card/card"
import { Section, Container } from "components/anti/grid/grid"
import { FooterBrand } from "components/fostive/footer-brand/footer-brand"

import { replaceUnicode } from "src/utils/mixins"

import bgSample from "assets/img/bg/bg-home.jpg"
// import imgSample169a from "assets/img/sample/img_sample_16-9-a.jpg"
// import imgSample169b from "assets/img/sample/img_sample_16-9-b.jpg"
// import imgSample169c from "assets/img/sample/img_sample_16-9-c.jpg"
// import imgSample169d from "assets/img/sample/img_sample_16-9-d.jpg"

const WorkPage = () => {
  const rawData = useStaticQuery(graphql`
    query {
      wordPress {
        pageBy(uri: "work") {
          blocks {
            name
            ... on WordPress_CoreParagraphBlock {
              attributes {
                ... on WordPress_CoreParagraphBlockAttributes {
                  content
                }
              }
            }
          }
        }
        project_categories {
          nodes {
            name
            slug
            description
            projectsTaxonomy {
              instagram
              coverImage {
                mediaItemUrl
                sourceUrl(size: MEDIUM)
              }
            }
          }
        }
      }
    }
  `)

  const backgroundRef = useRef()

  const setBackground = param => {
    backgroundRef.current.children[0].setAttribute(
      "style",
      `background: url(${param}) center center / cover no-repeat`
    )
  }

  const changeBackground = param => {
    setBackground(param)
  }

  const nodes = rawData.wordPress.project_categories.nodes
  const paragraph = rawData.wordPress.pageBy.blocks.find(
    block => block.name === "core/paragraph"
  )
  // const acfCoverMain = blocks.filter(item => item.name === "acf/cover-main")

  return (
    <>
      <Layout footer={false}>
        <SEO title="Work" />

        <Cover
          variant="basic"
          theme="dark"
          img={bgSample}
          forwardImgRef={backgroundRef}
          imgHeight="h-vh-100"
          className="cover-full text-cente d-none d-sm-block"
          contentMaxWidth="mw-100"
          sideDescription="work"
        >
          <div className="custom-sidebar">
            <div className="custom-menu-trigger">
              <ul className="navbar-nav ml-auto">
                {nodes &&
                  nodes.map((item, i) => {
                    return (
                      <li
                        className={`nav-item animated fadeInUp delayp${i + 1}`}
                        key={i}
                      >
                        <Link
                          className="nav-link"
                          activeClassName="active"
                          to={`/work/categories/${item.slug}`}
                          onMouseEnter={() =>
                            changeBackground(
                              item.projectsTaxonomy.coverImage.mediaItemUrl
                            )
                          }
                        >
                          <h3>{replaceUnicode(item.name)}</h3>
                        </Link>
                        <p className="text-truncate-twoline">
                          {replaceUnicode(item.description)}
                        </p>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        </Cover>

        <Section className="work mobile-layout text-white d-block d-sm-none">
          <Container className="mw-100">
            <div>
              <div>
                <h2 className="mb-3 h2 custom-title-works">Work</h2>
                <p className="mb-5">
                  {replaceUnicode(paragraph.attributes.content)}
                </p>

                <div>
                  {nodes &&
                    nodes.map((item, i) => {
                      return (
                        <Card
                          key={i}
                          variant="overlay"
                          title={replaceUnicode(item.name)}
                          titleClassName="h3"
                          img={item.projectsTaxonomy.coverImage.sourceUrl}
                          imgHeight="h-ratio-3-2"
                          link={`/work/categories/${item.slug}`}
                          className="mb-4"
                        />
                      )
                    })}
                </div>
              </div>
              <div>
                <FooterBrand mobile={true} />
              </div>
            </div>
          </Container>
        </Section>
      </Layout>
    </>
  )
}

export default WorkPage
